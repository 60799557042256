<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-none">
      <h3 class="text-xl font-bold">Resumen de la colaboración</h3>
      <p class="text-lg text-primary">Colaboración por invitación</p>
    </div>

    <div class="flex-1 py-normal">
      <div v-if="!collaboration.foodies_meeting" class="mt-normal">
        <i class="icon-calendar pr-4 inline-block text-xl"></i>
        <p class="inline-block text-lg">
          Del {{ startAt }} al {{ endAt }}
        </p>
      </div>
      <div v-else class="mt-normal">
        <i class="icon-calendar pr-4 inline-block text-xl"></i>
        <p class="inline-block text-lg capitalize">
          {{ $filters.moment(collaboration.start_at, 'dddd DD/MM/YY') }}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-timer pr-4 inline-block text-xl"></i>
        <p class="inline-block text-lg">
          {{ collaboration.allowed_week_days.length > 4 ? 'Toda la semana' : 'De lunes a jueves'}}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-userGroup pr-4 inline-block text-xl"></i>
        <p class="inline-block text-lg">
          Max. Acompañantes: {{ collaboration.foodies_meeting ? 0 : collaboration.companions_limit}}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-coupon pr-4 inline-block text-xl"></i>
        <p class="inline-block text-lg">
          {{ collaboration.discount_value + (collaboration.discount_type === 'prc' ? '% ' : '€ ') + 'de descuento'}}
        </p>
      </div>

      <div class="mt-normal">
        <i class="icon-coupon pr-4 inline-block text-xl"></i>
        <p class="inline-block text-lg">
          Requerimientos
        </p>
      </div>

      <div class="mt-normal">
        <p class="inline-block text-md mx-10">
          {{ collaboration.requirements ? collaboration.requirements : 'Sin requerimientos' }}
        </p>
      </div>

      <div class="pb-32 mt-normal">
        <div class="py-2">
          <div class="flex border rounded-full w-full p-1 items-center">
            <a-initials :src="foodieValues.profile_picture" :name="foodieValues.name" class="flex-none"/>
            <h2 class="pl-2 flex-auto">{{ foodieValues.ig_username ? '@' + foodieValues.ig_username : foodieValues.name }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 flex items-end pb-4">
    </div>
  </div>

  <a-alert hide-cancel :show="createModal" :confirm-class="'text-center text-primary block mx-auto'" :confirm-text="'Ver mis colaboraciones'" @confirm="$router.push({ path: '/collaboration' })">
    <div class="text-center font-bold text-lg block mx-auto w-4/5 mt-sm">
      <h2>¡Enhorabuena!</h2>
      <h2>Tu colaboración ha sido creada</h2>
    </div>
  </a-alert>

  <a-alert hide-cancel :show="errorModal" :confirm-class="'text-center text-primary block mx-auto'" :confirm-text="'Ver mis colaboraciones'" @confirm="$router.push({ path: '/collaboration' })">
    <div class="text-center font-bold text-lg block mx-auto w-4/5 mt-sm">
      <h2 class="text-pointred">¡Error!</h2>
      <h2>No se ha podido crear la colaboración</h2>
    </div>
  </a-alert>
</template>

<script>
export default {
  name: 'step-resume',
  props: {
    collaboration: { required: false, type: Object },
    createModal: { required: false, type: Boolean, default: false },
    errorModal: { required: false, type: Boolean, default: false },
    foodieValues: { required: false, type: Object }
  },
  data () {
    return {
      startAt: '',
      endAt: '',
      discount: '',
      foodiesSelected: []
    }
  },
  methods: {
    format () {
      return this.$filters.moment(this.collaboration.start_at, 'dd DD/MM/YY')
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.startAt = this.$filters.moment(this.collaboration.start_at, 'DD/MM/YY')
    this.endAt = this.$filters.moment(this.collaboration.end_at, 'DD/MM/YY')
    this.discount = this.collaboration.discount
  }
}
</script>

<style scoped>

</style>
