<template>
  <a-stepper :slots="5" class="h-screen flex flex-col overflow-auto pt-14 pb-12">

    <template #header="{ index, goPrev }">
      <div @click="prev(index, goPrev)" class="p-4 h-14 z-40 block my-auto fixed top-0 bg-white max-w-5xl mx-auto inset-x-0 lg:top-14 text-left cursor-pointer">
        <i class="icon-arrowLeft inline-block"></i>
        <h3 class="uppercase inline-block font-bold">atrás</h3>
      </div>
<!--      <div v-else class="px-4 pt-4 fixed top-0 bg-white max-w-5xl mx-auto inset-x-0 lg:top-auto text-left">-->
<!--        <h3 class="uppercase">nueva colaboración</h3>-->
<!--      </div>-->
    </template>

    <template #step-1>
      <step-1
        v-model:companions="collaboration.companions_limit"
      />
    </template>

    <template #step-2>
      <step-2
        v-model:discount-type="collaboration.discount_type"
        v-model:discount-value="collaboration.discount_value"
      />
    </template>

    <template #step-3>
      <step-3
        v-model:start-at="collaboration.start_at"
        v-model:end-at="collaboration.end_at"
        v-model:week-days="collaboration.allowed_week_days"
        @disable-next="disableNext = $event"
      />
    </template>

    <template #step-4>
      <step-4
        v-model:requirements="collaboration.requirements"
      />
    </template>

    <template #step-5>
      <step-5
        v-model:collaboration="collaboration"
        v-model:create-modal="modal.create"
        v-model:error-modal="modal.error"
        v-model:foodie-values="foodie"
      />
    </template>

    <template #footer="{ index, goNext }">
      <div class="fixed px-4 max-w-5xl mx-auto bottom-24 inset-x-0">
        <button :disabled="disableNext || loading.createCollab"
                @click="createCollab(index, goNext)"
                class="bg-primary text-white w-full rounded-full py-sm overflow-hidden disabled:bg-gray">
          <span v-if="loading.createCollab">
            <a-loader class="h-6 w-6 block mx-auto" :color="'text-white'"/>
          </span>
          <span v-else>
            {{ index === 5 ? 'Crear Colaboración' : 'Continuar'}}
          </span>
        </button>
      </div>
    </template>

  </a-stepper>
</template>

<script>
import Step1 from './steps/step-companions'
import Step2 from './steps/step-discount'
import Step3 from './steps/step-calendar'
import Step4 from './steps/step-requirements'
import Step5 from './steps/step-resume-private'
import { mapGetters, mapState } from 'vuex'
// import { ref } from 'vue'

export default {
  name: 'collaborationPrivate',
  data () {
    return {
      collaboration: {
        type: 'private',
        contact_name: '',
        contact_phone: '',
        discount_type: 'prc',
        discount_value: 100,
        companions_limit: 1,
        start_at: '',
        end_at: '',
        allowed_week_days: [1, 2, 3, 4],
        foodies_meeting: false,
        uses_limit: '',
        foodies: [],
        requirements: ''
      },
      disableNext: false,
      foodie: [],
      modal: {
        create: false
      },
      loading: {
        createCollab: null
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },
  methods: {
    prev (index, goPrev) {
      switch (index) {
        case 1: {
          this.$router.replace({ name: 'Explore' })
          break
        }
        default: {
          this.disableNext = false
          goPrev()
          break
        }
      }
    },
    createCollab (index, goNext) {
      if (index === 5) {
        this.loading.createCollab = true
        // if (this.collaboration.discount_type === 'fixed_e2') {
        //   this.collaboration.discount_value = this.collaboration.discount_value * 100

        // .push({ id: this.foodie.id })
        this.collaboration.foodies.push({ id: this.foodie.id })
        // console.log(this.collaboration.foodies[0].id)

        this.$repository.collaborations
          .store({
            ...this.collaboration, ...{ discount_value: this.collaboration.discount_type === 'fixed_e2' ? this.collaboration.discount_value * 100 : this.collaboration.discount_value }
          })
          .then(() => {
            this.modal.create = true
            this.loading.createCollab = false
          })
          .catch(() => (this.modal.create = true))
      } else {
        goNext()
      }
    }
  },
  created () {
    if (this.isAuthenticated) {
      this.collaboration.contact_name = this.user.restaurant.contact_name
      this.collaboration.contact_phone = this.user.restaurant.phone
      // this.collaboration.foodies.push(this.$route.params.id)

      this.$repository.foodies
        .show(this.$route.params.id)
        .then(({ data: response }) => {
          this.foodie = response
        })
        .then(() => {
          this.$repository.collaborations
            .requirements()
            .then(({ data }) => {
              this.collaboration.requirements = data.default_requirements ? data.default_requirements : ''
            })
        })
        .catch(() => (this.modal.error = true))
    }
  }
}
</script>

<style scoped>

</style>
